// USAAGO variables:
// Font colors
$font-color-dark: black;
$font-color-light: white;
$accent-font-color-light: rgb(225, 0, 25);
$accent-font-color-mid: rgb(225, 0, 25);
$accent-font-color: rgb(225, 0, 25);
$active-header-link-background-color: #646B71;

// Background colors
$background-color-light: white;
$accent-background-color-dark: rgb(225, 0, 25);
$accent-background-color-mid: #4B6172;
$accent-background-color-light: #74797D;
$accent-background-color: #44AC87;
$success-background-color: rgb(225, 0, 25);


// Shadow colors
$light-box-shadow-color: rgba(0, 0, 0, 0.4);
$box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.2), 0 0.375em 1.25em 0 rgba(0, 0, 0, 0.19);

// Alert Colors
$alert-font-color: #4B6172;
$success-color: #A4E3C9;
$danger-color: #f5c7c7;
$info-color: rgb(227, 238, 255);
$warning-color: #f18a8a;

// Measurements
$footer-height: 3.5em;
$footer-height-mobile: 10.3em;
$primary-header-height: 3.6875em;
$primary-header-height-collapsed: 3.5em;
$secondary-header-height: 5em;
$secondary-header-height-collapsed: 1em;
$border-radius: 15px;
$btn-border-radius: 10px;
